<template>
  <c-box>
    <c-text font-size="2xl"> Antropometri (Ukuran & komposisi tubuh) </c-text>

    <QShortAnswer
      v-if="data.weight"
      :question="data.weight.question"
      type="number"
      :value="data.weight.answer"
      @input="setAnswer('weight', +$event)"
      v-chakra
      :is-disabled="isDisabled"
      mt="30px"
    >
      <template #rightAddon>
        <c-input-right-addon>kg</c-input-right-addon>
      </template>
    </QShortAnswer>
    <QShortAnswer
      v-if="data.waist"
      :question="data.waist.question"
      :is-disabled="isDisabled"
      type="number"
      :value="data.waist.answer"
      @input="setAnswer('waist', +$event)"
    >
      <template #rightAddon>
        <c-input-right-addon>cm</c-input-right-addon>
      </template>
    </QShortAnswer>

    <QProgress
      v-if="data.changes"
      :question="data.changes.question"
      :is-disabled="isDisabled"
      :value="data.changes.answer"
      @input="setAnswer('changes', +$event)"
      :max-value="10"
    />

    <QShortAnswer
      v-if="data.fatPercentage"
      :question="data.fatPercentage.question"
      type="number"
      :value="data.fatPercentage.answer"
      @input="setAnswer('fatPercentage', +$event)"
      :is-disabled="isDisabled"
      v-chakra
      mt="30px"
    >
      <template #rightAddon>
        <c-input-right-addon>%</c-input-right-addon>
      </template>
    </QShortAnswer>

    <QShortAnswer
      v-if="data.muscle"
      :question="data.muscle.question"
      type="number"
      :value="data.muscle.answer"
      @input="setAnswer('muscle', +$event)"
      :is-disabled="isDisabled"
      v-chakra
      mt="30px"
    >
      <template #rightAddon>
        <c-input-right-addon>kg</c-input-right-addon>
      </template>
    </QShortAnswer>

    <QShortAnswer
      v-if="data.bodyWater"
      :question="data.bodyWater.question"
      type="number"
      :value="data.bodyWater.answer"
      @input="setAnswer('bodyWater', +$event)"
      :is-disabled="isDisabled"
      v-chakra
      mt="30px"
    >
      <template #rightAddon>
        <c-input-right-addon>%</c-input-right-addon>
      </template>
    </QShortAnswer>
    <QShortAnswer
      v-if="data.visceralFat"
      :question="data.visceralFat.question"
      type="number"
      :value="data.visceralFat.answer"
      @input="setAnswer('visceralFat', +$event)"
      :is-disabled="isDisabled"
      v-chakra
      mt="30px"
    />
    <QShortAnswer
      v-if="data.boneMass"
      :question="data.boneMass.question"
      type="number"
      :value="data.boneMass.answer"
      @input="setAnswer('boneMass', +$event)"
      :is-disabled="isDisabled"
      v-chakra
      mt="30px"
    />
    <QShortAnswer
      v-if="data.basalMetabolicRate"
      :question="data.basalMetabolicRate.question"
      type="number"
      :value="data.basalMetabolicRate.answer"
      @input="setAnswer('basalMetabolicRate', +$event)"
      :is-disabled="isDisabled"
      v-chakra
      mt="30px"
    />

    <template v-for="item in unhandledQuestions">
      <pre :key="item.id">{{ item.id }} {{ item.question }}</pre>
    </template>
  </c-box>
</template>

<script >
import QShortAnswer from "@/components/quizionary/short-answer.vue";
import QProgress from "@/components/quizionary/QProgress";
import { useEqualWatch, useQuestion, useSubmittedValue } from "./helper-mixin";
import { computed } from "@vue/composition-api";
import _ from "lodash";
const __sfc_main = {};
__sfc_main.props = ["profile", "value", "disabled"];

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const questions = computed(() => props.value);
  const isDisabled = computed(() => props.disabled ?? false);
  let data = useQuestion(questions, {
    8: "weight",
    9: "waist",
    10: "changes",
    11: "fatPercentage",
    12: "muscle",
    13: "bodyWater",
    14: "visceralFat",
    15: "boneMass",
    16: "basalMetabolicRate",
    "6a878926-1d06-4fdf-b019-343ad92015c1": "weight",
    "5f07e1bb-8bda-45a2-a720-16b8c9aa7d80": "waist",
    "10f8e868-4ba7-47da-9803-fa32dbfdf336": "changes",
    "dffffad1-243d-456f-9807-3e3abaed9857": "fatPercentage",
    "b6780300-3d28-4bcf-ad4f-a6a093681d08": "muscle",
    "4bd79fe0-d798-428d-a928-4a54c2654be7": "bodyWater",
    "73f22591-44ba-463e-81b3-eddf2fe2a4b7": "visceralFat",
    "d78a8257-0371-489e-ac8e-cf7e30bba77e": "boneMass",
    "d98d7fa6-a63a-48e6-84af-354bc124c819": "basalMetabolicRate"
  });
  const unhandledQuestions = computed(() => data.value?.unhandledQuestions);
  let submittedValue = useSubmittedValue(data);
  useEqualWatch(submittedValue, data => {
    emit("input", data);
  });

  function setAnswer(name, value) {
    let val = _.cloneDeep(data.value);

    _.set(val, `${name}.answer`, value);

    data.value = val;
  }

  return {
    isDisabled,
    data,
    unhandledQuestions,
    setAnswer
  };
};

__sfc_main.components = Object.assign({
  QShortAnswer,
  QProgress
}, __sfc_main.components);
export default __sfc_main;
</script>

<style scoped>
div[data-chakra-component="CInputRightAddon"] {
  background: none;
  border: none;
}
</style>
