<template>
  <c-box>
    <c-text font-size="2xl">Aktivitas Harian</c-text>

    <c-form-control v-if="data.jenisOlahraga" mt="30px">
      <c-form-label
        fontSize="16px"
        color="#555555"
        fontWeight="400"
        fontFamily="Roboto"
      >
        Jenis olahraga yang dilakukan
      </c-form-label>
      <c-checkbox-group
        size="lg"
        variant-color="green"
        spacing="2"
        v-model="sportType_"
        required
      >
        <template v-for="item in sportTypeList_">
          <c-checkbox
            :key="item.type"
            :value="item.type"
            :is-disabled="
              isDisabled || sportType_.includes('Tidak ada') && item.type !== 'Tidak ada'
            "
          >
            {{ item.type }}
          </c-checkbox>
          <c-form-control
            :key="`duration-${item.type}`"
            v-if="sportType_.includes(item.type) && item.type !== 'Tidak ada'"
          >
            <c-form-label
              fontSize="16px"
              color="#555555"
              fontWeight="400"
              fontFamily="Roboto"
            >
              Durasi
            </c-form-label>
            <c-select
              height="62px"
              placeholder="Duration"
              v-model="durations[item.type]"
              required
            >
              <option value="Tidak melakukan atau kurang dari 10 menit">
                Tidak melakukan atau kurang dari 10 menit
              </option>
              <option value="10 - 15 menit">10 - 15 menit</option>
              <option value="16 - 30 menit">16 - 30 menit</option>
              <option value="31 - 45 menit">31 - 45 menit</option>
              <option value="46 - 60 menit">46 - 60 menit</option>
              <option value="Lebih dari 60 menit">Lebih dari 60 menit</option>
            </c-select>
          </c-form-control>
        </template>
      </c-checkbox-group>
    </c-form-control>
    <QDropdown
      v-if="data.aktifWeekdays"
      :selections="[
        { id: '1', answer: 'Dominan berbaring' },
        { id: '2', answer: 'Dominan duduk' },
        { id: '3', answer: 'Banyak berdiri' },
        { id: '4', answer: 'Banyak jalan kaki' },
      ]"
      :question="data.aktifWeekdays.question"
      :value="data.aktifWeekdays.answer"
      @input="setAnswer('aktifWeekdays', $event)"
      v-chakra
      mt="30px"
      :is-disabled="isDisabled"
    />
    <QDropdown
      v-if="data.aktifWeekend"
      :selections="[
        { id: '1', answer: 'Dominan berbaring' },
        { id: '2', answer: 'Dominan duduk' },
        { id: '3', answer: 'Banyak berdiri' },
        { id: '4', answer: 'Banyak jalan kaki' },
      ]"
      :question="data.aktifWeekend.question"
      :value="data.aktifWeekend.answer"
      @input="setAnswer('aktifWeekend', $event)"
      v-chakra
      mt="30px"
      :is-disabled="isDisabled"
    />
    <template v-for="item in unhandledQuestions">
      <pre :key="item.id">{{ item.id }} {{ item.question }}</pre>
    </template>
  </c-box>
</template>

<script >
import QDropdown from "@/components/quizionary/dropdown";
import { computed, reactive, ref } from "@vue/composition-api";
import { getSubmittedValue, useEqualWatch, useQuestion } from "./helper-mixin";
import _ from "lodash";
const __sfc_main = {};
__sfc_main.props = ["value", "profile", "disabled"];

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const questions = computed(() => props.value);
  const isDisabled = computed(() => props.disabled ?? false);
  const sportTypeList_ = reactive([{
    type: "Tidak ada",
    duration: ""
  }, {
    type: "Jalan santai",
    duration: ""
  }, {
    type: "Jalan cepat",
    duration: ""
  }, {
    type: "Naik turun tangga",
    duration: ""
  }, {
    type: "Jogging",
    duration: ""
  }, {
    type: "Senam Aerobic/Cardio dan sejenisnya",
    duration: ""
  }, {
    type: "Strength Training dan sejenisnya",
    duration: ""
  }, {
    type: "Zumba/HIIT dan sejenisnya",
    duration: ""
  }, {
    type: "Yoga/Pilates dan sejenisnya",
    duration: ""
  }, {
    type: "Sepeda",
    duration: ""
  }, {
    type: "Renang",
    duration: ""
  }, {
    type: "Futsal/Sepakbola/Tennis/Bulutangkis",
    duration: ""
  }]);
  const data = useQuestion(questions, {
    32: "jenisOlahraga",
    33: "aktifWeekdays",
    34: "aktifWeekend",
    "ddbd6178-18d0-4dee-84b2-cbe771419c5a": "jenisOlahraga",
    "102f0d9a-7a85-4487-b498-32943aea1153": "aktifWeekdays",
    "4ae6e6ab-0597-4040-b8ea-720574e907d1": "aktifWeekend"
  });
  const unhandledQuestions = computed(() => data.value.unhandledQuestions);
  const sportType_ = ref([]);
  const durations = ref({});
  const sportType = computed(() => sportType_.value.map(it => ({
    type: it,
    duration: durations.value[it]
  })));
  useEqualWatch(sportType, sportType => {
    data.value.jenisOlahraga.answer = sportType;
  });
  useEqualWatch([data, sportType], ([data, sportType]) => {
    if (data == null || sportType == null) return;
    if (sportType.length !== 0) return;
    let jenisOlahraga = data.jenisOlahraga?.answer ?? [];

    for (let item of jenisOlahraga) {
      sportType_.value.push(item.type);
      durations.value[item.type] = item.duration;
    }
  }, {
    immediate: true
  });
  const submittedValue = computed(() => {
    let items = getSubmittedValue(data.value);
    return items.filter(it => !Array.isArray(it));
  });
  useEqualWatch(submittedValue, value => {
    emit("input", value);
  }, {
    deep: true
  });

  function setAnswer(name, value) {
    let val = _.cloneDeep(data.value);

    _.set(val, `${name}.answer`, value);

    data.value = val;
  }

  return {
    isDisabled,
    sportTypeList_,
    data,
    unhandledQuestions,
    sportType_,
    durations,
    setAnswer
  };
};

__sfc_main.components = Object.assign({
  QDropdown
}, __sfc_main.components);
export default __sfc_main;
</script>

<style scoped></style>
