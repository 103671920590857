import { render, staticRenderFns } from "./aktifitas-harian.vue?vue&type=template&id=32fd3b22&scoped=true&"
import script from "./aktifitas-harian.vue?vue&type=script&lang=js&"
export * from "./aktifitas-harian.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32fd3b22",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CText: require('@chakra-ui/vue').CText, CFormLabel: require('@chakra-ui/vue').CFormLabel, CCheckbox: require('@chakra-ui/vue').CCheckbox, CSelect: require('@chakra-ui/vue').CSelect, CFormControl: require('@chakra-ui/vue').CFormControl, CCheckboxGroup: require('@chakra-ui/vue').CCheckboxGroup, CBox: require('@chakra-ui/vue').CBox})
