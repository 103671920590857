var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('c-box',[_c('c-text',{attrs:{"font-size":"2xl"}},[_vm._v("Pola Makan")]),(_vm.data.rasaLapar)?_c('QProgress',{directives:[{name:"chakra",rawName:"v-chakra"}],attrs:{"question":_vm.data.rasaLapar.question,"max-value":10,"is-disabled":_vm.isDisabled,"value":_vm.data.rasaLapar.answer,"mt":"30px"},on:{"input":function($event){return _vm.setAnswer('rasaLapar', $event)}},scopedSlots:_vm._u([{key:"minLabel",fn:function(){return [_vm._v("Sangat lapar")]},proxy:true},{key:"maxLabel",fn:function(){return [_vm._v("Sangat kenyang")]},proxy:true}],null,false,1387713480)}):_vm._e(),(_vm.data.rasaKenyang)?_c('QProgress',{directives:[{name:"chakra",rawName:"v-chakra"}],attrs:{"question":_vm.data.rasaKenyang.question,"max-value":10,"is-disabled":_vm.isDisabled,"value":_vm.data.rasaKenyang.answer,"mt":"30px"},on:{"input":function($event){return _vm.setAnswer('rasaKenyang', $event)}},scopedSlots:_vm._u([{key:"minLabel",fn:function(){return [_vm._v("Sangat lapar")]},proxy:true},{key:"maxLabel",fn:function(){return [_vm._v("Sangat kenyang")]},proxy:true}],null,false,1387713480)}):_vm._e(),(_vm.data.makanBerat)?_c('QRadio',{directives:[{name:"chakra",rawName:"v-chakra"}],attrs:{"question":_vm.data.makanBerat.question,"selections":[
      { id: 2, answer: '1 porsi/hari' },
      { id: 3, answer: '2 porsi/hari' },
      { id: 4, answer: '3 porsi/hari' },
      { id: 5, answer: 'Lebih dari 3 porsi/hari' } ],"value":_vm.data.makanBerat.answer,"is-disabled":_vm.isDisabled,"mt":"30px"},on:{"input":function($event){return _vm.setAnswer('makanBerat', $event)}}}):_vm._e(),(_vm.data.makanCemilan)?_c('QRadio',{directives:[{name:"chakra",rawName:"v-chakra"}],attrs:{"question":_vm.data.makanCemilan.question,"selections":[
      { id: 1, answer: 'Hampir tidak ada' },
      { id: 2, answer: '1 porsi/hari' },
      { id: 3, answer: '2 porsi/hari' },
      { id: 4, answer: '3 porsi/hari' },
      { id: 5, answer: 'Lebih dari 3 porsi/hari' } ],"value":_vm.data.makanCemilan.answer,"is-disabled":_vm.isDisabled,"mt":"30px"},on:{"input":function($event){return _vm.setAnswer('makanCemilan', $event)}}}):_vm._e(),(_vm.data.konsumsiCairan)?_c('QRadio',{directives:[{name:"chakra",rawName:"v-chakra"}],attrs:{"value":_vm.data.konsumsiCairan.answer,"question":_vm.data.konsumsiCairan.question,"selections":[
      { id: 1, answer: '8 gelas/hari (2 liter/hari)' },
      {
        id: 2,
        answer: 'Kurang dari 8 gelas/hari (kurang dari 2 liter/hari)',
      },
      { id: 3, answer: 'Lebih dari 8 gelas/hari (lebih dari 2 liter/hari)' } ],"mt":"30px","is-disabled":_vm.isDisabled},on:{"input":function($event){return _vm.setAnswer('konsumsiCairan', $event)}}}):_vm._e(),(_vm.data.konsumsiSayuran)?_c('QRadio',{directives:[{name:"chakra",rawName:"v-chakra"}],attrs:{"question":_vm.data.konsumsiSayuran.question,"selections":[
      { id: 1, answer: 'Tidak pernah' },
      { id: 2, answer: '1 porsi/hari' },
      { id: 3, answer: '2 porsi/hari' },
      { id: 4, answer: '3 porsi/hari' },
      { id: 5, answer: 'Lebih dari 3 porsi/hari' } ],"value":_vm.data.konsumsiSayuran.answer,"mt":"30px","is-disabled":_vm.isDisabled},on:{"input":function($event){return _vm.setAnswer('konsumsiSayuran', $event)}}}):_vm._e(),(_vm.data.konsumsiBuah)?_c('QRadio',{directives:[{name:"chakra",rawName:"v-chakra"}],attrs:{"question":_vm.data.konsumsiBuah.question,"selections":[
      { id: 1, answer: 'Tidak pernah' },
      { id: 2, answer: '1 porsi/hari' },
      { id: 3, answer: '2 porsi/hari' },
      { id: 4, answer: '3 porsi/hari' },
      { id: 5, answer: 'Lebih dari 3 porsi/hari' } ],"value":_vm.data.konsumsiBuah.answer,"mt":"30px","is-disabled":_vm.isDisabled},on:{"input":function($event){return _vm.setAnswer('konsumsiBuah', $event)}}}):_vm._e(),(_vm.data.konsumsiCairan2)?_c('QRadio',{directives:[{name:"chakra",rawName:"v-chakra"}],attrs:{"value":_vm.data.konsumsiCairan2.answer,"question":_vm.data.konsumsiCairan2.question,"selections":[
      { id: 1, answer: '8 gelas/hari (2 liter/hari)' },
      {
        id: 2,
        answer: 'Kurang dari 8 gelas/hari (kurang dari 2 liter/hari)',
      },
      { id: 3, answer: 'Lebih dari 8 gelas/hari (lebih dari 2 liter/hari)' } ],"mt":"30px","is-disabled":_vm.isDisabled},on:{"input":function($event){return _vm.setAnswer('konsumsiCairan2', $event)}}}):_vm._e(),(_vm.data.konsumsiMinumanKemasan)?_c('QRadio',{directives:[{name:"chakra",rawName:"v-chakra"}],attrs:{"value":_vm.data.konsumsiMinumanKemasan.answer,"question":_vm.data.konsumsiMinumanKemasan.question,"selections":[
      { id: '1', answer: 'Tidak pernah' },
      { id: '2', answer: '1 gelas atau 250 ml/hari' },
      { id: '3', answer: '2 gelas atau 500 ml/hari' },
      { id: '4', answer: '3 gelas atau 750 ml/hari' },
      { id: '5', answer: '4 gelas atau 1 liter/hari' },
      { id: '6', answer: 'Lebih dari 4 gelas atau 1 liter/hari' } ],"mt":"30px","is-disabled":_vm.isDisabled},on:{"input":function($event){return _vm.setAnswer('konsumsiMinumanKemasan', $event)}}}):_vm._e(),(_vm.data.konsumsiGorengan)?_c('QRadio',{directives:[{name:"chakra",rawName:"v-chakra"}],attrs:{"value":_vm.data.konsumsiGorengan.answer,"question":_vm.data.konsumsiGorengan.question,"selections":[
      { id: '1', answer: 'Tidak pernah' },
      { id: '2', answer: '1 potong/hari' },
      { id: '3', answer: '2 potong/hari' },
      { id: '4', answer: '3 potong/hari' },
      { id: '6', answer: 'Lebih dari 3 potong/hari' } ],"mt":"30px","is-disabled":_vm.isDisabled},on:{"input":function($event){return _vm.setAnswer('konsumsiGorengan', $event)}}}):_vm._e(),(_vm.data.konsumsiMakananAsin)?_c('QRadio',{directives:[{name:"chakra",rawName:"v-chakra"}],attrs:{"value":_vm.data.konsumsiMakananAsin.answer,"question":_vm.data.konsumsiMakananAsin.question,"selections":[
      { id: '1', answer: 'Tidak pernah' },
      { id: '2', answer: '1 bungkus/hari' },
      { id: '3', answer: '2 bungkus/hari' },
      { id: '4', answer: '3 bungkus/hari' },
      { id: '6', answer: 'Lebih dari 3 bungkus/hari' } ],"mt":"30px","is-disabled":_vm.isDisabled},on:{"input":function($event){return _vm.setAnswer('konsumsiMakananAsin', $event)}}}):_vm._e(),(_vm.data.konsumsiMakananManis)?_c('QRadio',{directives:[{name:"chakra",rawName:"v-chakra"}],attrs:{"value":_vm.data.konsumsiMakananManis.answer,"question":_vm.data.konsumsiMakananManis.question,"selections":[
      { id: '1', answer: 'Tidak pernah' },
      { id: '2', answer: '1 potong/hari' },
      { id: '3', answer: '2 potong/hari' },
      { id: '4', answer: '3 potong/hari' },
      { id: '6', answer: 'Lebih dari 3 potong/hari' } ],"mt":"30px","is-disabled":_vm.isDisabled},on:{"input":function($event){return _vm.setAnswer('konsumsiMakananManis', $event)}}}):_vm._e(),_vm._l((_vm.unhandledQuestions),function(item){return [_c('pre',{key:item.id},[_vm._v(_vm._s(item.id)+" "+_vm._s(item.question))])]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }