<template>
  <c-box>
    <c-text font-size="2xl">Pola Makan</c-text>

    <QProgress
      v-if="data.rasaLapar"
      :question="data.rasaLapar.question"
      :max-value="10"
      :is-disabled="isDisabled"
      :value="data.rasaLapar.answer"
      @input="setAnswer('rasaLapar', $event)"
      v-chakra
      mt="30px"
    >
      <template #minLabel>Sangat lapar</template>
      <template #maxLabel>Sangat kenyang</template>
    </QProgress>

    <QProgress
      v-if="data.rasaKenyang"
      :question="data.rasaKenyang.question"
      :max-value="10"
      :is-disabled="isDisabled"
      :value="data.rasaKenyang.answer"
      @input="setAnswer('rasaKenyang', $event)"
      v-chakra
      mt="30px"
    >
      <template #minLabel>Sangat lapar</template>
      <template #maxLabel>Sangat kenyang</template>
    </QProgress>

    <QRadio
      v-if="data.makanBerat"
      :question="data.makanBerat.question"
      :selections="[
        { id: 2, answer: '1 porsi/hari' },
        { id: 3, answer: '2 porsi/hari' },
        { id: 4, answer: '3 porsi/hari' },
        { id: 5, answer: 'Lebih dari 3 porsi/hari' },
      ]"
      :value="data.makanBerat.answer"
      @input="setAnswer('makanBerat', $event)"
      v-chakra
      :is-disabled="isDisabled"
      mt="30px"
    />
    <QRadio
      v-if="data.makanCemilan"
      :question="data.makanCemilan.question"
      :selections="[
        { id: 1, answer: 'Hampir tidak ada' },
        { id: 2, answer: '1 porsi/hari' },
        { id: 3, answer: '2 porsi/hari' },
        { id: 4, answer: '3 porsi/hari' },
        { id: 5, answer: 'Lebih dari 3 porsi/hari' },
      ]"
      :value="data.makanCemilan.answer"
      @input="setAnswer('makanCemilan', $event)"
      v-chakra
      :is-disabled="isDisabled"
      mt="30px"
    />
    <QRadio
      v-if="data.konsumsiCairan"
      :value="data.konsumsiCairan.answer"
      @input="setAnswer('konsumsiCairan', $event)"
      :question="data.konsumsiCairan.question"
      :selections="[
        { id: 1, answer: '8 gelas/hari (2 liter/hari)' },
        {
          id: 2,
          answer: 'Kurang dari 8 gelas/hari (kurang dari 2 liter/hari)',
        },
        { id: 3, answer: 'Lebih dari 8 gelas/hari (lebih dari 2 liter/hari)' },
      ]"
      v-chakra
      mt="30px"
      :is-disabled="isDisabled"
    />
    <QRadio
      v-if="data.konsumsiSayuran"
      :question="data.konsumsiSayuran.question"
      :selections="[
        { id: 1, answer: 'Tidak pernah' },
        { id: 2, answer: '1 porsi/hari' },
        { id: 3, answer: '2 porsi/hari' },
        { id: 4, answer: '3 porsi/hari' },
        { id: 5, answer: 'Lebih dari 3 porsi/hari' },
      ]"
      :value="data.konsumsiSayuran.answer"
      @input="setAnswer('konsumsiSayuran', $event)"
      v-chakra
      mt="30px"
      :is-disabled="isDisabled"
    />
    <QRadio
      v-if="data.konsumsiBuah"
      :question="data.konsumsiBuah.question"
      :selections="[
        { id: 1, answer: 'Tidak pernah' },
        { id: 2, answer: '1 porsi/hari' },
        { id: 3, answer: '2 porsi/hari' },
        { id: 4, answer: '3 porsi/hari' },
        { id: 5, answer: 'Lebih dari 3 porsi/hari' },
      ]"
      :value="data.konsumsiBuah.answer"
      @input="setAnswer('konsumsiBuah', $event)"
      v-chakra
      mt="30px"
      :is-disabled="isDisabled"
    />

    <QRadio
      v-if="data.konsumsiCairan2"
      :value="data.konsumsiCairan2.answer"
      @input="setAnswer('konsumsiCairan2', $event)"
      :question="data.konsumsiCairan2.question"
      :selections="[
        { id: 1, answer: '8 gelas/hari (2 liter/hari)' },
        {
          id: 2,
          answer: 'Kurang dari 8 gelas/hari (kurang dari 2 liter/hari)',
        },
        { id: 3, answer: 'Lebih dari 8 gelas/hari (lebih dari 2 liter/hari)' },
      ]"
      v-chakra
      mt="30px"
      :is-disabled="isDisabled"
    />
    <QRadio
      v-if="data.konsumsiMinumanKemasan"
      :value="data.konsumsiMinumanKemasan.answer"
      @input="setAnswer('konsumsiMinumanKemasan', $event)"
      :question="data.konsumsiMinumanKemasan.question"
      :selections="[
        { id: '1', answer: 'Tidak pernah' },
        { id: '2', answer: '1 gelas atau 250 ml/hari' },
        { id: '3', answer: '2 gelas atau 500 ml/hari' },
        { id: '4', answer: '3 gelas atau 750 ml/hari' },
        { id: '5', answer: '4 gelas atau 1 liter/hari' },
        { id: '6', answer: 'Lebih dari 4 gelas atau 1 liter/hari' },
      ]"
      v-chakra
      mt="30px"
      :is-disabled="isDisabled"
    />
    <QRadio
      v-if="data.konsumsiGorengan"
      :value="data.konsumsiGorengan.answer"
      @input="setAnswer('konsumsiGorengan', $event)"
      :question="data.konsumsiGorengan.question"
      :selections="[
        { id: '1', answer: 'Tidak pernah' },
        { id: '2', answer: '1 potong/hari' },
        { id: '3', answer: '2 potong/hari' },
        { id: '4', answer: '3 potong/hari' },
        { id: '6', answer: 'Lebih dari 3 potong/hari' },
      ]"
      v-chakra
      mt="30px"
      :is-disabled="isDisabled"
    />
    <QRadio
      v-if="data.konsumsiMakananAsin"
      :value="data.konsumsiMakananAsin.answer"
      @input="setAnswer('konsumsiMakananAsin', $event)"
      :question="data.konsumsiMakananAsin.question"
      :selections="[
        { id: '1', answer: 'Tidak pernah' },
        { id: '2', answer: '1 bungkus/hari' },
        { id: '3', answer: '2 bungkus/hari' },
        { id: '4', answer: '3 bungkus/hari' },
        { id: '6', answer: 'Lebih dari 3 bungkus/hari' },
      ]"
      v-chakra
      mt="30px"
      :is-disabled="isDisabled"
    />
    <QRadio
      v-if="data.konsumsiMakananManis"
      :value="data.konsumsiMakananManis.answer"
      @input="setAnswer('konsumsiMakananManis', $event)"
      :question="data.konsumsiMakananManis.question"
      :selections="[
        { id: '1', answer: 'Tidak pernah' },
        { id: '2', answer: '1 potong/hari' },
        { id: '3', answer: '2 potong/hari' },
        { id: '4', answer: '3 potong/hari' },
        { id: '6', answer: 'Lebih dari 3 potong/hari' },
      ]"
      v-chakra
      mt="30px"
      :is-disabled="isDisabled"
    />

    <template v-for="item in unhandledQuestions">
      <pre :key="item.id">{{ item.id }} {{ item.question }}</pre>
    </template>
  </c-box>
</template>

<script >
import _ from "lodash";
import equal from "fast-deep-equal";
import { useQuestion } from "./helper-mixin";
import QProgress from "@/components/quizionary/QProgress.vue";
import QRadio from "@/components/quizionary/radio-answer.vue";
import { computed, inject, watch, isReactive } from "@vue/composition-api";
const __sfc_main = {};
__sfc_main.props = ["profile", "value", "disabled"];

__sfc_main.setup = (__props, __ctx) => {
  const $log = inject("$logJson");
  const props = __props;
  const emit = __ctx.emit;
  const questions = computed(() => props.value);
  const isDisabled = computed(() => props.disabled ?? false);
  const data = useQuestion(questions, {
    17: "rasaLapar",
    18: "rasaKenyang",
    19: "makanBerat",
    20: "makanCemilan",
    21: "konsumsiCairan",
    22: "konsumsiSayuran",
    23: "konsumsiBuah",
    24: "konsumsiCairan2",
    25: "konsumsiMinumanKemasan",
    26: "konsumsiGorengan",
    27: "konsumsiMakananAsin",
    28: "konsumsiMakananManis",
    "d77125db-2d1b-462e-83c9-ea66ea6a58d9": "rasaLapar",
    "25b8c081-79f4-4eda-a319-0f52762d1776": "rasaKenyang",
    "eeea3ee1-5409-47c4-89b5-e746edba1d3e": "makanBerat",
    "79062f44-e356-4b2d-bc68-3d9f0e6a3dd6": "makanCemilan",
    "905a9cfb-3340-49f1-b418-2f25e25d2c45": "konsumsiCairan",
    "326da1c3-e4f1-4779-8d6f-1613006b28cc": "konsumsiSayuran",
    "6eed79f0-6e41-493d-a6b8-33246e95cd03": "konsumsiBuah",
    "17f63e88-69b9-412f-95d6-9e751393c421": "konsumsiCairan2",
    "33872b6c-9c80-470c-a581-23af357235a8": "konsumsiMinumanKemasan",
    "763830a4-43fc-4824-9fdd-9c9e01c770b4": "konsumsiGorengan",
    "f4ff1b82-54ed-4fce-9227-264f9935b659": "konsumsiMakananAsin",
    "9ad544c5-81dc-4743-8a59-ead6c6d7185c": "konsumsiMakananManis"
  });
  const unhandledQuestions = computed(() => data.value.unhandledQuestions);
  const arrData = computed(() => {
    let {
      unhandledQuestions,
      ...value
    } = data.value;
    let items = Object.values(value);
    return _.orderBy(items, "id");
  });
  watch(arrData, (data, old) => {
    if (equal(data, old)) return; // $log("-data", data);

    emit("input", data);
  });

  function setAnswer(name, value) {
    let val = _.cloneDeep(data.value);

    _.set(val, `${name}.answer`, value);

    data.value = val;
  }

  return {
    isDisabled,
    data,
    unhandledQuestions,
    setAnswer
  };
};

__sfc_main.components = Object.assign({
  QProgress,
  QRadio
}, __sfc_main.components);
export default __sfc_main;
</script>

<style scoped></style>
