<template>
  <c-box>
    <c-text font-size="2xl">Perasaan &amp; Pengetahuan</c-text>

    <QProgress
      v-if="data.perasaan"
      :max-value="5"
      :question="data.perasaan.question"
      :is-disabled="isDisabled"
      :value="data.perasaan.answer"
      @input="setAnswer('perasaan', $event)"
      v-chakra
      mt="30px"
    >
      <template #minLabel>Kurang semangat</template>
      <template #maxLabel>Sangat semangat</template>
    </QProgress>

    <QParagraph
      v-if="data.dipelajari"
      :question="data.dipelajari.question"
      :value="data.dipelajari.answer"
      @input="setAnswer('dipelajari', $event)"
      :is-disabled="isDisabled"
      v-chakra
      mt="30px"
    />

    <QFileAnswer
      v-if="data.fotoProgress"
      :is-uploading="isUploading"
      :question="data.fotoProgress.question"
      subtext="Foto maximal 3MB"
      :value="data.fotoProgress.answer"
      @input="setAnswer('fotoProgress', $event)"
      v-chakra
      mt="30px"
      @upload="onUpload($event)"
      :is-disabled="isDisabled"
    />

    <template v-for="item in unhandledQuestions">
      <pre :key="item.id">{{ item.id }} {{ item.question }}</pre>
    </template>
  </c-box>
</template>

<script >
import QProgress from "@/components/quizionary/QProgress";
import QParagraph from "@/components/quizionary/paragraph-answer";
import QFileAnswer from "@/components/quizionary/QFile";
import { computed, ref } from "@vue/composition-api";
import { getSubmittedValue, useEqualWatch, useQuestion } from "./helper-mixin";
import _ from "lodash";
const __sfc_main = {};
__sfc_main.props = ["value", "disabled"];

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const questions = computed(() => props.value);
  const isDisabled = computed(() => props.disabled ?? false);
  let isUploading = ref(false);
  const data = useQuestion(questions, {
    29: "perasaan",
    30: "dipelajari",
    31: "fotoProgress",
    "955e7216-2697-4477-9ffd-b1c0055c1131": "perasaan",
    "0f5ad7a4-c3c5-4b38-91ab-f8ae7bfdccb0": "dipelajari",
    "fca1fc3e-1c49-4fa9-9b5a-0fd3f0dc70e2": "fotoProgress"
  });
  const unhandledQuestions = computed(() => data.value.unhandledQuestions);
  const submittedValue = computed(() => getSubmittedValue(data.value));
  useEqualWatch(unhandledQuestions, function (data) {
    console.log("unhandledQuestions", data);
  }, {
    immediate: true
  });
  useEqualWatch(submittedValue, data => {
    data = data.filter(it => !Array.isArray(it));
    console.log("got data", data);
    emit("input", data);
  }, {
    immediate: true
  });

  async function onUpload(file) {
    let form = new FormData();
    form.append("file", file);
    isUploading.value = true;
    let url = await this.axios.post(`/v1/clients/progress-tracker/upload`, form).then(r => r.data.data.url); // data.value.fotoProgress.answer = url

    setAnswer('fotoProgress', url);
    isUploading.value = false;
  }

  function setAnswer(name, value) {
    console.log("setAnswer", name, value);

    let val = _.cloneDeep(data.value);

    _.set(val, `${name}.answer`, value);

    data.value = val;
  }

  return {
    isDisabled,
    isUploading,
    data,
    unhandledQuestions,
    onUpload,
    setAnswer
  };
};

__sfc_main.components = Object.assign({
  QProgress,
  QParagraph,
  QFileAnswer
}, __sfc_main.components);
export default __sfc_main;
</script>

<style scoped></style>
